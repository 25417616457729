import React, { useEffect } from "react";
import styles from './styles.module.scss';

import Tree from "../../components/svg/Tree";

const Home = () => {

  useEffect(() => {
    document.title = process.env.REACT_APP_NAME + " | Home"        
  },[])

  return (    
    <div className={styles.wrapper}>
      <div className={['container',styles.container].join(' ')}>
        <div className={styles.svgChange}>
          <Tree style={{width : '600px', maxWidth: '100%'}} />
        </div>
      </div> 
    </div>  
  );            
};

export default Home;