import React from "react";
import { Outlet } from "react-router-dom";

import styles from "./styles.module.scss";

const BaseLayout = () => {

  return (    
    <div className={styles.baseWrapper}>
      <Outlet/>              
    </div>
  )
};

export default BaseLayout;